function _make(tagName, classNames = null, attributes = {}) {
  const el = document.createElement(tagName);
  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }
  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }
  return el;
}

function updateGoodViews(previous, good, callback) {
  const paramsContainer = document.querySelector(".params");
  let paramsBlock = null;
  if (previous == null) {
    paramsBlock = _make("div");
    paramsBlock.setAttribute("data-param", "p-" + good.id);
    paramsBlock.classList.add("d-flex");
  } else {
    paramsBlock = _make("div");
    paramsBlock.setAttribute("data-param", "p-" + previous + "-" + good.id);
    paramsBlock.classList.remove("d-flex");
  }

  good.childs.forEach((item) => {
    const param = _make("button", ["param", "btn", "btn-outline-light"], {
      innerHTML: `${item.name}`,
      onclick: (e) => {
        let hideItems = null;
        let clearSelectItems = null;
        if (previous != null) {
          hideItems = document.querySelectorAll(
            "[data-param^=p-" + previous + "-" + good.id + "-" + item.id + "-]"
          );
          clearSelectItems = document.querySelectorAll(
            "[data-param^=p-" + previous + "-" + good.id + "-]"
          );
        } else {
          hideItems = document.querySelectorAll(
            "[data-param^=p-" + good.id + "-]"
          );
          clearSelectItems = document.querySelectorAll("[data-param^=p-]");
        }

        clearSelectItems.forEach((item) => {
          item.querySelectorAll(".param").forEach((item) => {
            item.classList.remove("selected");
          });
        });
        hideItems.forEach((item) => {
          item.style.classList.remove("d-flex");
        });

        let paramsBlock = null;
        if (previous != null) {
          paramsBlock = document.querySelector(
            "[data-param^=p-" + previous + "-" + good.id + "-" + item.id + "]"
          );
        } else {
          paramsBlock = document.querySelector(
            "[data-param^=p-" + good.id + "-" + item.id + "]"
          );
        }
        if (paramsBlock != null) {
          paramsBlock.classList.add("d-flex");
        }
        e.target.classList.add("selected");
        if (item.childs.length === 0) {
          callback(item);
        } else {
          callback(null);
        }
      },
    });
    param.setAttribute("type", "submit");
    paramsBlock.appendChild(param);
    paramsContainer.appendChild(paramsBlock);

    if (previous != null) {
      updateGoodViews(previous + "-" + good.id, item);
    } else {
      updateGoodViews(good.id, item);
    }
  });
}

export default updateGoodViews;
