import { Tooltip } from "bootstrap";

export const slideDown = (element, targetHeight, duration, afterCallback) => {
  element.style.overflow = "hidden";
  element.style.display = "block";
  let currentHeight = element.clientHeight;
  const interval = 10;
  const step = ((targetHeight - currentHeight) / duration) * interval;

  var slideInterval = setInterval(() => {
    currentHeight += step;
    element.style.height = currentHeight + "px";

    if (currentHeight >= targetHeight) {
      clearInterval(slideInterval);
      element.style.height = "";
      element.style.overflow = "";

      if (afterCallback) {
        afterCallback();
      }
    }
  }, interval);
};

export const slideUp = (element, targetHeight, duration, afterCallback) => {
  element.style.overflow = "hidden";
  let currentHeight = element.clientHeight;
  const interval = 10;
  const step = ((currentHeight - targetHeight) / duration) * interval;

  const slideInterval = setInterval(function () {
    currentHeight -= step;
    element.style.height = currentHeight + "px";

    if (currentHeight <= targetHeight) {
      clearInterval(slideInterval);
      element.style.height = targetHeight;
      element.style.overflow = "";

      if (afterCallback) {
        afterCallback();
      }
    }
  }, interval);
};

const fallbackCopyTextToClipboard = (text) => {
  return new Promise((resolve, reject) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      document.body.removeChild(textArea);
      if (successful) {
        return resolve();
      }
    } catch (err) {
      document.body.removeChild(textArea);
      return reject(err);
    }
    return reject();
  });
};

export const copyTextToClipboard = (text) => {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  }
  return navigator.clipboard.writeText(text);
};

export const initCopyButton = () => {
  const btnEls = document.querySelectorAll(".js-copy-btn");
  for (const btnEl of btnEls) {
    btnEl.addEventListener("click", async (event) => {
      const text =
        event.target.parentElement.parentElement.querySelector(".js-copy-text");
      const tooltip = new Tooltip(btnEl, {
        trigger: "manual",
        title: "Ключ скопирован!",
      });
      try {
        copyTextToClipboard(text.innerText);
        tooltip.show();
      } catch (err) {
        console.error(err);
        tooltip._config.title = "Ключ не удалось скопировать";
        tooltip.show();
      }
      setTimeout(() => {
        tooltip.hide();
      }, 2000);
    });
  }
};

export const formatPrice = (number) => {
  return number.toLocaleString("ru-RU");
};

export const pluralizeText = (count, oneText, fewText, manyText) => {
  if (count % 10 === 1 && count % 100 !== 11) {
    return oneText;
  } else if (
    count % 10 >= 2 &&
    count % 10 <= 4 &&
    !(count % 100 >= 12 && count % 100 <= 14)
  ) {
    return fewText || oneText + "а";
  } else {
    return manyText || oneText + "ов";
  }
};

export const addPrefetch = (kind, url, as) => {
  const linkEl = document.createElement("link");
  linkEl.rel = kind;
  linkEl.href = url;
  if (as) {
    linkEl.as = as;
  }
  document.head.append(linkEl);
};
