import { Modal, Dropdown } from "bootstrap";
import updateGoodViews from "./goods.mjs";
import {
  slideDown,
  slideUp,
  initCopyButton,
  formatPrice,
  pluralizeText,
  addPrefetch,
} from "./helpers.mjs";

const initModal = () => {
  const modalEl = document.querySelector(".modal");
  if (!modalEl) {
    return;
  }
  window.modal = new Modal(modalEl);
  window.modal._backdrop._isAppended = true;
  window.modal._backdrop._element = document.querySelector(".modal-backdrop");
  window.modal._isShown = true;

  const closeBtnEl = document.querySelector('[data-dismiss="modal"]');
  closeBtnEl.addEventListener("click", () => {
    window.modal.hide();
  });

  modalEl.addEventListener("hidden.bs.modal", (event) => {
    document.body.setAttribute("style", "");
  });
};

const initQuantityParam = () => {
  const containerEls = document.querySelectorAll(".qty-param");
  for (const containerEl of containerEls) {
    const inputEl = containerEl.querySelector("input");
    const decreaseEl = containerEl.querySelector(".btn-decrease");
    const increaseEl = containerEl.querySelector(".btn-increase");
    const qtyEl = containerEl.querySelector(".qty");
    const qtyTextEl = containerEl.querySelector(".qty-text");
    const priceEl = containerEl.parentNode.querySelector(".price");
    const priceAmountEl = priceEl.querySelector(".amount");
    const pricePerQty = priceEl.dataset.price;
    const initialQty = inputEl.value;

    decreaseEl?.addEventListener("click", (e) => {
      inputEl.value--;
      onInputChange(inputEl);
    });

    increaseEl?.addEventListener("click", (e) => {
      inputEl.value++;
      onInputChange(inputEl);
    });

    const onInputChange = (target) => {
      const qty = parseInt(target.value, 10);
      const min = target.min;
      const max = target.max;
      let newQty = qty;
      if (isNaN(qty)) {
        newQty = initialQty;
      } else if (qty > max) {
        newQty = max;
      } else if (qty < min) {
        newQty = min;
      }
      // debugger;

      for (const el of [decreaseEl, increaseEl]) {
        el.disabled = false;
      }

      if (newQty == max) {
        increaseEl.disabled = true;
      } else if (newQty == min) {
        decreaseEl.disabled = true;
      }

      target.value = newQty;
      qtyEl.textContent = newQty;
      qtyTextEl.textContent = pluralizeText(
        newQty,
        i18n.device_word_form_1,
        i18n.device_word_form_2,
        i18n.device_word_form_3
      );
      priceAmountEl.textContent = formatPrice(pricePerQty * newQty);
    };

    inputEl?.addEventListener("change", (e) => {
      onInputChange(e.target);
    });

    onInputChange(inputEl);
  }
};

const initYoutubeWidget = () => {
  const ytVideoEls = document.querySelectorAll(".yt-video");

  const addIframe = (el) => {
    if (el.activated) return;
    el.activated = true;

    const videoId = el.dataset.vid;
    const params = new URLSearchParams([]);
    params.append("autoplay", "1");
    params.append("playsinline", "1");
    params.append("hl", "ru");
    params.append("rel", "0");

    const iframeEl = document.createElement("iframe");
    iframeEl.width = "100%";
    iframeEl.height = "100%";
    iframeEl.allow =
      "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture";
    iframeEl.allowFullscreen = true;
    iframeEl.src = `https://www.youtube-nocookie.com/embed/${encodeURIComponent(
      videoId
    )}?${params.toString()}`;
    el.append(iframeEl);

    iframeEl.focus();

    setTimeout(() => {
      el.classList.add("activated");
    }, 400);
  };

  const warmConnections = (el) => {
    if (el.preconnected) return;

    // The iframe document and most of its subresources come right off youtube.com
    addPrefetch("preconnect", "https://www.youtube-nocookie.com");
    // The botguard script is fetched off from google.com
    addPrefetch("preconnect", "https://www.google.com");

    // Not certain if these ad related domains are in the critical path. Could verify with domain-specific throttling.
    addPrefetch("preconnect", "https://googleads.g.doubleclick.net");
    addPrefetch("preconnect", "https://static.doubleclick.net");

    el.preconnected = true;
  };

  for (const ytVideoEl of ytVideoEls) {
    ytVideoEl.addEventListener("click", () => {
      addIframe(ytVideoEl);
    });
    ytVideoEl.addEventListener(
      "pointerover",
      () => {
        warmConnections(ytVideoEl);
      },
      { once: true }
    );
  }
};

const initModalVideo = () => {
  const mediaEls = document.querySelectorAll(".modal-media");
  for (const mediaEl of mediaEls) {
    const videoEl = mediaEl.querySelector("video");
    videoEl?.addEventListener("canplaythrough", (e) => {
      mediaEl.classList.add("play");
      videoEl.play();
    });
  }
};

const initReadMoreButton = () => {
  const btnMoreEls = document.querySelectorAll(".btn-more");
  for (const btnMoreEl of btnMoreEls) {
    btnMoreEl.addEventListener("click", () => {
      const reviewsContainer = btnMoreEl.previousElementSibling;
      if (reviewsContainer.classList.contains("opened")) {
        reviewsContainer.classList.remove("opened");
        slideUp(reviewsContainer, 500, 350, () => {
          btnMoreEl.classList.remove("opened");
        });
      } else {
        slideDown(reviewsContainer, reviewsContainer.scrollHeight, 350, () => {
          reviewsContainer.classList.add("opened");
          btnMoreEl.classList.add("opened");
        });
      }
    });
  }
};

const initLanguageSwitcher = () => {
  const dropdownToggles = document.querySelectorAll(
    "#language-switcher .dropdown-toggle"
  );
  dropdownToggles.forEach(
    (dropdownToggle) =>
      new Dropdown(dropdownToggle, {
        offset: [0, 0],
      })
  );
};

document.addEventListener("DOMContentLoaded", () => {
  initModal();
  initReadMoreButton();
  initModalVideo();
  initCopyButton();
  initQuantityParam();
  initYoutubeWidget();
  initLanguageSwitcher();
  if (typeof good_data !== "undefined") {
    updateGoodViews(null, good_data, (item) => {
      const button = document.querySelector(".good-add-to-cart");
      const stockValue = document.querySelector(".quantity-in-stock");
      if (item != null) {
        stockValue.classList.add("d-block");
        document.querySelector(".quantity-in-stock .value").innerHTML =
          item.quantity_in_stock;
        button.setAttribute("href", "/cart/add/" + item.id);
        if (item.quantity_in_stock > 0) {
          button.classList.remove("disabled");
        } else {
          button.classList.add("disabled");
        }
      } else {
        stockValue.classList.remove("d-block");
        button.removeAttribute("href");
        button.classList.add("disabled");
      }
    });
  }
});

// const findSlug = (item) => {
//   if (item === document) return null;
//   const dataslug = item.getAttribute("data-slug");
//   if (dataslug == null && item.parentNode != null) {
//     return findSlug(item.parentNode);
//   }
//   return dataslug;
// };
//
// const initModal = (modal) => {
//   const closeBtn = modal.querySelector(".close");
//   const open = () => modal.classList.add("d-block", "fade");
//   const close = () => {
//     modal.classList.remove("d-block", "fade");
//     history.pushState({ view: null }, "", "/");
//   };
//
//   closeBtn?.addEventListener("click", () => {
//     close();
//   });
//
//   return {
//     open,
//     close,
//   };
// };
//
// const updateModal = (html) => {
//   const modal = document.querySelector(".modal");
//   modal.innerHTML = html;
//   const { open, close } = initModal(modal);
//   initForm(modal.querySelector("form"));
//   setTimeout(() => {
//     if (html) {
//       open();
//     } else {
//       close();
//     }
//   }, 50);
// };
//
// const makeRequest = async (url, method = "GET", body) => {
//   try {
//     console.log("makeRequest", url, method, body);
//     const response = await fetch(url, {
//       method,
//       headers: {
//         "X-Requested-With": "XMLHttpRequest",
//       },
//       body,
//     });
//     return await response.json();
//   } catch (err) {
//     console.error(err);
//   }
// };
//
// const initForm = (form) => {
//   form?.addEventListener("submit", async (e) => {
//     // e.preventDefault();
//     // try {
//     //   const { action, method: formMethod } = e.target;
//     //   if (!action) {
//     //     throw new Error("no action");
//     //   }
//     //   const method = formMethod || "POST";
//     //   const formData = new FormData(e.target);
//     //   const body = new URLSearchParams(formData);
//     //   const response = await makeRequest(action, method, body);
//     //   updateModal(response.view);
//     //   console.log("response", response);
//     // } catch (err) {
//     //   console.error(err);
//     // }
//   });
// };
//
// document.addEventListener("DOMContentLoaded", () => {
//   const modal = document.querySelector(".modal");
//   const page = document.body.dataset.page;
//   const { open, close } = initModal(modal);
//
//   const form = document.querySelector("form");
//   initForm(form);
//
//   modal.addEventListener("click", (e) => {
//     if (e.target === modal) {
//       close();
//     }
//   });
//
//   window.addEventListener("popstate", (e) => {
//     console.log("popstate", e);
//     if (e.state != null) {
//       updateModal(e.state.view);
//     } else {
//       updateModal("");
//     }
//   });
//
//   const html = document.querySelector("html");
//
//   html.addEventListener("click", async (e) => {
//     let slug = findSlug(e.target);
//     if (slug == null) {
//       return;
//     }
//     if (location.pathname === slug) {
//       return;
//     }
//     e.preventDefault();
//     const data = await makeRequest(slug);
//     updateModal(data.view);
//     history.pushState(data, data.title, slug);
//   });
// });
